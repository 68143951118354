/*#region Navbar */

.primary-header {
  padding: var(--size-400) 0;
}

.nav {
  --max-width: 1700px;
  --container-padding: 2rem;

  width: min(var(--max-width), 100% - (var(--container-padding) * 2));
  margin-inline: auto;
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: center;
}

.nav-logo {
  margin: auto 0;
  max-width: 12rem;
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-mobile img {
  width: 2rem;
  cursor: pointer;
}

@media (min-width: 30em) {
  .nav-mobile {
    display: none !important;
  }

  .nav-extended-links {
    display: block !important;
  }
}

@media (max-width: 30em) {
  .nav-extended-links {
    display: none !important;
  }

  .nav-mobile {
    display: block !important;
  }
}

.nav-mobile-links {
  display: grid;
  padding-top: 1.5rem;
  gap: 0.6rem;
  text-align: center;
  justify-content: center;
  padding-bottom: 0.5rem;
}

.nav-link {
  cursor: pointer;
  text-decoration: none;
  color: var(--clr-neutral-black);
  font-size: var(--fs-625);
}

/*#endregion*/

/*#region Hero */

@media (min-width: 54em) {
  #home {
    background-color: rgba(114, 189, 132, 0.75);
    background-image: url("../img/Hero_Background.webp");
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.hr-wrapper {
  padding: var(--size-500) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media (max-width: 54em) {
  .hr-wrapper {
    background-color: rgba(114, 189, 132, 0.75);
    background-image: url("../img/Hero_Background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }
}

.hr-header {
  font-size: var(--fs-750);
  line-height: 1.1;
  margin-top: var(--size-300);
  margin-bottom: var(--size-500);
  text-align: justify;
  hyphens: auto;
  word-break: word-break;
}

.hr-sub-header {
  font-size: var(--fs-625);
  line-height: 1.5;
  margin-bottom: var(--size-500);
  display: block;
  text-align: center;
}

.hr-info {
  font-size: var(--fs-500);
  line-height: 2;
  margin-bottom: 1em;
  text-align: center;
  hyphens: auto;
  max-width: 32ch;
}

/*#endregion*/

/*#region About */

.ab-img {
  border-radius: 3rem 0 3rem 0;
}

.ab-citaction {
  line-height: 1.5;
  font-size: var(--fs-citactions);
  font-style: italic;
  hyphens: auto;
  margin-bottom: var(--size-500);
}

/*#endregion*/

/*#region Blog */

#blog {
  position: relative;
}

.bl-card-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  padding-top: 2rem;
  justify-content: center;
  align-items: center;
}

.bl-card {
  display: grid;
  grid-template-columns: 0.85fr 0.15fr;
  gap: 0.25rem;
  background-color: var(--clr-accent-400);
  border-radius: 1rem;
  box-shadow: 0 1.125em 1em -1em var(--clr-accent-500);
  padding: 1rem 1rem;
  min-height: 8rem;
  color: var(--clr-neutral-white);
  cursor: pointer;
}

.bl-card-content {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.bl-card-control {
  display: flex;
  align-items: center;
}

.bl-card-details {
  grid-area: details;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.bl-card-details-img {
  height: 1.5rem;
}

.bl-card-details-date {
  font-size: var(--fs-500);
}

.bl-card-header {
  grid-area: cont;
  font-size: var(--fs-625);
}

.bl-card-btn {
  cursor: pointer;
  margin: 0 auto;
}

.bl-card-btn:hover {
  opacity: 0.7;
}

.bl-card-btn-icon {
  height: 2.5rem;
  margin: 0;
  border-radius: 100vmax;
  background-color: var(--clr-neutral-white);
  padding: 0.5rem;
}

/*#endregion*/

/*#region Events */

#events {
  background-image: url("../img/Events_background.png");
  background-position: top;
  background-repeat: no-repeat;
}

.ap-year {
  font-size: var(--fs-500);
}

.event-list-header {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
}

.ap-card-list {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

.ap-card {
  display: grid;
  gap: 0.5rem;
  background-color: var(--clr-neutral-white);
  border-radius: 1rem;
  box-shadow: 0 1.125em 1em -1em var(--clr-accent-500);
  padding: 1rem 1rem;
  cursor: pointer;
}

.ap-card-body {
  display: grid;
  grid-template-columns:
    "1fr 1fr"
    "1fr";
  gap: 1rem;
}

.ap-card-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.ap-card-control {
  display: flex;
  justify-content: center;
}

.ap-card-signUp-info {
  font-size: var(--size-300);
  text-align: center;
}

.ap-card-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.btn[data-section="ap"] {
  margin-top: 2rem;
  border-radius: 1rem;
}

/*#endregion*/

/*#region Footer */

.primary-footer-wrapper {
  display: grid;
  justify-content: center;
  align-items: center;
  gap: 3em;
  grid-template-columns: 1fr;
}

@media (max-width: 50em) {
  .primary-footer-wrapper > * {
    margin-inline: auto;
  }
}

.primary-footer-links {
  display: inline-flex;
  justify-content: center;
  gap: clamp(var(--size-200), 30vw, var(--size-900));
}

.footer-logo-links img {
  height: var(--size-700);
}

/* footer navigation */

.footer-nav ul {
  display: inline-flex;
  gap: clamp(var(--size-200), 30vw, var(--size-700));
  list-style: none;
}

.footer-nav a {
  color: var(--clr-neutral-black);
  text-decoration: none;
}

.footer-nav a:is(:hover, :focus) {
  color: var(--clr-accent-400);
}

/*#endregion*/

/*#region Contact */

#contact {
  background-color: rgba(114, 189, 132, 0.75);
  background-image: url("../img/Hero_Background.webp");
  background-repeat: no-repeat;
  background-size: cover;
  height: 80%;
}

/*#endregion Contact */

/*#region Utility classes */

.document-hidden {
  display: none;
  visibility: hidden;
}

.paragraph-wrapper {
  text-align: center;
  display: block;
}

.paragraph-wrapper p {
  text-align: justify;
  hyphens: auto;
}

.paragraph-wrapper a {
  text-decoration: none;
  color: var(--clr-link);
}

.even-areas {
  display: grid;
}

@media (min-width: 54em) {
  .even-areas {
    grid-template-columns: 40% 60%;
  }
}

@media (max-width: 54em) {
  .even-areas {
    grid-template-areas:
      "area-img"
      "area-content";
  }

  .hr-wrapper {
    grid-area: area-content;
  }

  .hr-img-wrapper {
    grid-area: area-img;
  }
}

.even-areas[data-type="gap"] {
  gap: var(--size-300);
}

.even-columns {
  display: grid;
  gap: 1rem;
}

@media (min-width: 50em) {
  .even-columns {
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }
}

.text-center {
  text-align: center;
}

.text-center p {
  margin-inline: auto;
}

.fw-bold {
  font-weight: var(--fw-bold);
}
.fw-semi-bold {
  font-weight: var(--fw-semi-bold);
}
.fw-regular {
  font-weight: var(--fw-regular);
}

.fs-primary-heading {
  padding-top: var(--size-500);
  font-size: var(--fs-primary-heading);
  line-height: 1.1;
  margin-bottom: var(--size-500);
}

.padding-block-900 {
  padding: var(--size-900) 0;
}

.padding-block-700 {
  padding: var(--size-700) 0;
}

.padding-block-500 {
  padding: var(--size-500) 0;
}

.margin-block-500 {
  margin: var(--size-500) 0;
}

.padding-inline {
  padding-right: var(--size-500);
  padding-left: var(--size-500);
}

.horizontal-line {
  border-bottom: 1px solid var(--clr-neutral-white);
  width: 100%;
}

/*#endregion*/

/*#region Modal */

.overlay {
  background: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 998;
}

/* Modal */

.modal {
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 1em;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 15px;
  z-index: 999;
  height: 90%;
  padding: 2rem 1.6rem;
  overflow: auto !important;
}

.modal::-webkit-scrollbar {
  width: 10px;
  padding-right: 4px;
}
::-webkit-scrollbar-track {
  background: var(--clr-neutral-white);
  border-radius: 15px;
  height: 100%;
  margin-right: 2px;
}
::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.modal-btn-close {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  margin-top: 1em;
  margin-right: 1em;
}

.modal-confirmation-info {
  margin-top: 4rem;
  display: grid;
  gap: 1em;
}

.modal-icon {
  height: 2.5rem;
}

.modal-btn-close img {
  height: 2rem;
}

/* Event Modal */

.modal-event-body {
  display: flex;
  flex-direction: column;
  gap: 1em;
  text-align: center;
}

.modal-heading {
  font-size: var(--fs-650);
}

.modal-event-sub-heading {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  justify-content: center;
}
.modal-event-info {
  padding-top: 1em;
  display: grid;
  gap: 1em;
}

.modal-event-info-wrapper {
  display: flex;
  justify-content: flex-start;
  padding: 0 2em;
  align-items: flex-start;
  gap: 3em;
}

.modal-event-infolist-wrapper {
  display: flex;
  padding: 0 2em;
  align-items: flex-start;
  gap: 3em;
  justify-content: center;
}

.modal-event-infolist {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 1em;
}

.modal-event-infolist ul {
  list-style: none;
  display: grid;
  gap: 1em;
}

.modal-event-info-text {
  align-self: center;
}

.modal-event-info-address {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  line-height: 2;
}

/* Blog Modal */

.blog-modal-body {
  margin-top: 2em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1em;
  padding: 0 1em;
}

.blog-modal-date {
  font-size: var(--fs-625);
  font-weight: var(--fw-bold);
}

.blog-modal-content {
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  gap: 1em;
}

.blog-modal-img {
  margin: auto;
}

.blog-modal-text span {
  margin: auto;
  text-align: justify;
  hyphens: auto;
  word-wrap: break-word;
}

.blog-modal-text a {
  color: var(--clr-link);
}

.blog-modal-text-center {
  margin: 1rem auto !important;
  display: grid;
  gap: 1rem;
  text-align: center !important;
}

.blog-modal-img-slider {
  position: relative;
}

#slider-control-prev {
  height: 2rem;
  left: 0;
  background-color: var(--clr-shadow);
  top: 44%;
  position: absolute;
}

#slider-control-next {
  height: 2rem;
  right: 0;
  top: 44%;
  position: absolute;
}

.blog-modal-img-slider-control {
  background-color: var(--clr-shadow);
  cursor: pointer;
}

.slider-img-inactive {
  display: none;
}

.slider-img-active {
  display: block;
}

/*#endregion Modal */

/*#region Form */

form {
  display: grid;
  gap: 1rem;
}

.form-control-info {
  font-size: var(--size-300);
  vertical-align: middle;
  text-align: center;
}

.form-control-info span {
  color: red;
}

.form-control-info input {
  margin-right: 0.5em;
  width: 1em;
  height: 1em;
}

.form-control {
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-direction: column;
  gap: 0.5em;
}

.form-label {
  font-size: var(--size-300);
}

.form-header {
  font-weight: bold;
  font-size: var(--fs-650);
  margin: 0.5rem 0;
}

.form-label span {
  color: red;
}

.form-input {
  padding: var(--size-200);
  font-size: var(--size-400);
  border-radius: 1em;
}

.form-control textarea {
  height: 10rem;
}

/*#endregion Form */

.wrapper-datenschutz ul {
  margin-left: 3em;
}

/*#region Media Queries */

/* Large */
@media (min-width: 78em) {
  /*#region General */

  .btn {
    font-size: var(--fs-600);
    width: 20rem;
    margin: 0 auto;
  }

  .btn-icon-text {
    font-size: var(--fs-600);
  }

  .btn-icon img {
    max-width: 1.8rem;
  }

  .fs-primary-heading {
    font-size: var(--size-700);
    padding-bottom: 1rem;
  }

  p {
    font-size: var(--fs-600);
  }

  .paragraph-wrapper p {
    max-width: 50ch;
  }

  /*#endregion General */

  /*#region Navbar */

  .nav-logo {
    max-width: 15rem;
  }

  /*#endregion Home */

  /*#region Home */

  #home {
    background-size: contain;
    background-position: left;
  }

  .hr-wrapper {
    justify-content: flex-start;
    padding-top: 15vh;
    padding-left: 5em;
    padding-right: 5em;
  }

  .hr-header {
    font-size: var(--fs-900);
  }

  .hr-sub-header {
    font-size: var(--fs-700);
  }

  .hr-info {
    font-size: var(--fs-600);
    max-width: 60ch;
    margin-bottom: 3em;
  }

  .hr-img {
    border-radius: 0 0 0 10rem;
  }

  /*#endregion Home */

  /*#region About */

  #about .padding-block-700 {
    padding: 5em 5em;
  }

  .ab-end img {
    transform: scale(1);
  }

  .ab-img {
    border-radius: 10rem 0 0 10rem;
    height: 90%;
  }

  .ab-citaction {
    font-size: var(--fs-625);
    margin-bottom: 2em;
  }

  /*#endregion About */

  /*#region Blog */

  .bl-card-list {
    max-width: 80rem;
    margin: auto;
    height: 40rem;
    overflow-y: scroll;
    padding-bottom: 2rem;
    padding-right: 2rem;
  }

  .bl-card-list::-webkit-scrollbar {
    width: 1rem;
  }

  .bl-card-list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 3px var(--clr-shadow);
    background-color: var(--clr-shadow);
    border-radius: 0.6rem;
  }

  .bl-card-list::-webkit-scrollbar-thumb {
    border-radius: 0.6rem;
  }

  .bl-card-list::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
  }

  .bl-card-content {
    margin-left: 2rem;
    justify-content: center;
  }

  /*#endregion Blog */

  /*#region Event */

  .event-list-header {
    margin-right: 18rem;
  }

  .ap-year {
    font-size: var(--fs-625);
  }

  .ap-card-list {
    max-width: 80rem;
    margin: auto;
  }

  .ap-card {
    grid-template-columns: 0.85fr 0.15fr;
  }

  .ap-card-control {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ap-card-body {
    grid-template-columns: 0.2fr 0.5fr 0.3fr;
    align-items: center;
  }

  .ap-card-content {
    justify-content: flex-start;
    align-items: center;
  }

  .ap-card-header {
    font-size: var(--size-500);
  }

  /*#endregion Event */

  /*#region Footer */

  .primary-footer-wrapper {
    grid-template-columns: 1fr 1fr;
  }

  .primary-footer-nav {
    display: flex;
    justify-content: center;
  }

  /*#endregion Footer */

  /*#region Modal */

  .modal {
    width: 40%;
    overflow: hidden;
  }

  .blog-modal-img {
    width: 30rem;
  }

  .blog-modal-body {
    margin-top: 3rem;
  }

  .blog-modal-text {
    max-width: 42ch;
    text-align: left;
  }

  .modal-event-info {
    margin-bottom: 2rem;
  }

  /*#endregion Modal */

  #contact {
    padding: 0 30%;
  }

  /*#region Home */
  /*#endregion Home */
}

/* Computer */
@media (min-width: 64em) {
}

/* Laptops */
@media (min-width: 54em) and (max-width: 64em) {
}

/* Tablet */
@media (min-width: 30em) and (max-width: 54em) {
  .ab-img {
    margin: 0 auto;
  }
}

/*#endregion*/
